import { BrowserRouter, Switch, Route, } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { LoginButton, Nav, } from './components';
import { Home, About, Blog, } from './views';
import './App.css';
import './layout.css';
import './media-queries.css';

const homeLinks = [
  {
    to: '/about',
    label: 'About',
    exact: true,
    component: About,
  },
  {
    to: '/blog',
    label: 'Blog',
    component: Blog,
  },
];

function App() {

  return (
    <BrowserRouter>
      <div className="app">
        <div className="app-container">
          <Container maxWidth="md">
            <Switch>
              {homeLinks.map(({ to, path, component }) => (
                <Route key={to} path={to} component={component} />
              ))}
              <Route
                path="/"
                exact
                component={Home}
              />
            </Switch>
          </Container>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
