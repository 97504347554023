import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from './Button';
import { Text } from './Text';
import { Grid } from '@material-ui/core';

export const LoginButton = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();

  const btnText = isAuthenticated ? 'Access requested' : 'Request to view work samples';

  if (isLoading) return (
    <div>
      <Text type="b3">
        Loading...
      </Text>
    </div>
  )

  return (
    <Grid container direction="column" spacing={4}>
      {!isAuthenticated && (
        <Grid item>
          <Text type="b3">
            To view work samples, please log in using Auth0. You&apos;ll be temporarily redirected for authentication.
          </Text>
        </Grid>      
      )}
      <Grid item>
        <Button disabled={isAuthenticated} onClick={loginWithRedirect}>
          {btnText}
        </Button>
      </Grid>
      {isAuthenticated && (
        <Grid item>
          <Text type="b3">
            Thanks for requesting access to my work samples! I review each request and will grant access here to view proprietary projects soon.
          </Text>
        </Grid>
      )}
    </Grid>
  );
};
