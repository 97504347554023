import { useHistory } from 'react-router-dom';

const useNavigation = () => {
  const history = useHistory();

  const handleNavigation = (path) => history.push(path);

  return handleNavigation;
};

export default useNavigation;
