import React from 'react';
import './Button.css';

export const Button = ({ children, left, right, onClick, stretch, disabled = false }) => {
  let classNames = 'btn';

  if (stretch) classNames += ' stretch';
  if (right) classNames += ' transition-right';
  if (left) classNames += ' transition-left';

  const attrs = {
    className: classNames,
    onClick,
  };

  if (disabled) attrs.disabled = true;

  return (
    <button {...attrs}>
      {children}
    </button>
  );
};
