import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Column, Row, Img, Text, Button, } from '../components';
import useNavigation from '../useNavigation';
import selfPencilGraphic from '../self-pencil.png';

export const Home = () => {
  const handleNavigation = useNavigation();
  const handleSelectContact = () => window.open('mailto:contact@michael-burns.io');

  return (
    <Grid container direction="row" spacing={4} xs={12}>
      <Grid item xs={12} sm={6}>
        <Grid container direction="column" spacing={4}>
          <Grid item sm>
            <Text type="h1">
              Hi there, I'm Michael Burns.
            </Text>
          </Grid>
          <Grid item sm>
            <Text type="b3" size="large">
              I build learning experiences for humans. Thanks for visiting.
            </Text>
          </Grid>
          <Grid item sm>
            <Text type="b3" size="large">
              Right now I'm working on my site, so check back soon :)
            </Text>
          </Grid>
          <Grid item>
            <Button stretch onClick={handleSelectContact}>
              Send me an email
            </Button>
          </Grid>
          <Grid item>
            <Button stretch right onClick={() => handleNavigation('/about')}>
              About
            </Button>
          </Grid>
          <Grid item>
            <Text type="h3">
              Coming soon!
            </Text>
          </Grid>
          <Grid item>
            <Button disabled stretch onClick={() => handleNavigation('/blog')}>
              Blog
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid item xs>
          <Img src={selfPencilGraphic} alt="" />
        </Grid>
      </Hidden>
    </Grid>
  );
};
