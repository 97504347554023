import React from 'react';
import { useParams } from 'react-router-dom';
import { Row } from '../components';

export const Blog = () => {
  const { blogId } = useParams();

  return (
    <Row>
      Blog {blogId}
    </Row>
  );
};
