import React from 'react';
import { Grid } from '@material-ui/core';
import { Column, Row, Img, Text, LoginButton, Button, } from '../components';
import useNavigation from '../useNavigation';

export const About = () => {
  const handleNavigation = useNavigation();

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Button left onClick={() => handleNavigation('/')}>
          &larr; Home
        </Button>
      </Grid>
      <Grid item>
        <Text type="h2">
          About
        </Text>
      </Grid>
      <Grid item>
        <Text type="h3">
          Me
        </Text>
      </Grid>
      <Grid item>
        <Text type="b1">
          I&apos;m a father of two wacky kids and lucky partner to my wife, Lindsey, living in
          Tempe, AZ. When it's not too hot outside, I love biking and hiking in our beautiful 
          town. 
        </Text>
      </Grid>
      <Grid item>
        <Text type="b1">
          From my beginnings of my career as a public educator, I now find myself at the crossroads 
          of personal development and tech. I've experienced the power of change both as a student and
          as a coach. I&apos;ve had the chance to learn from incredible mentors and am eager to help
          others at scale.
        </Text>
      </Grid>
      <Grid item>
        <Text type="h3">
          Some of my work
        </Text>
      </Grid>
      <Grid item>
        <Text type="b3">
          I&apos;ll be posting some side projects here soon, so be sure to check back!
        </Text>
      </Grid>
      <Grid item>
        <LoginButton />
      </Grid>
    </Grid>
  );
};
