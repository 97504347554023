import React from 'react';
import './Text.css';

export const Text = ({ type, children, size }) => {
  let C;

  if (type.indexOf('b') > -1) C = (children) => <p>{children}</p>;
  else if (type.indexOf('h1') > -1) C = (children) => <h1>{children}</h1>;
  else if (type.indexOf('h2') > -1) C = (children) => <h2>{children}</h2>;
  else if (type.indexOf('h3') > -1) C = (children) => <h3>{children}</h3>;

  return (
    <div className={'text-container ' + size} type={type}>
      {C(children)}
    </div>
  )
};
